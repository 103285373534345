import Script from 'next/script';
import {useEffect} from 'react';
import config from '@root/config';
import NProgress from 'nprogress';
import Router, {useRouter} from 'next/router';
import {appWithTranslation} from 'next-i18next';
import GoogleTracker from '@root/trackers/google';
import nextI18nConfig from '../next-i18next.config';
import {ToastContainer, Slide} from 'react-toastify';
import RewardsService from '@root/services/rewards';
import GoogleMapService from '@root/services/googlemap';
import ClevertapTracker from '@root/trackers/clevertap';
import {ProvideMediaMatchers} from 'react-media-match';
import UserAgentService from '@root/services/useragent';
import SessionTimeout from '@root/common/SessionTimeout';
import LocationPicker from '@root/common/LocationPicker';
import TrackingService, {PAGE_VIEW} from '@root/services/tracking';

import '../styles/globals.scss';
import 'nprogress/nprogress.css';
import '@root/assets/styles/owl.carousel.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@root/assets/styles/owl.theme.default.min.css';
import 'node_modules/jodit/es2021/jodit.min.css';
import LocaleService from '@root/services/locale';

NProgress.configure({showSpinner: false});
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function Barakat({Component, pageProps}) {
  LocaleService.current = pageProps?.locale || 'en';
  const router = useRouter();

  const setLoaded = () => {
    GoogleMapService.init();
  };

  const setLoadedGtm = () => {
    GoogleTracker.init({
      page: 'home',
      path: router.asPath,
    });
  };

  const setLoadedCleverTap = () => {
    ClevertapTracker.init();
  };

  const handleRouteChange = url => {
    TrackingService.triggerEvent(PAGE_VIEW, {url});
  };

  useEffect(() => {
    let clientDevice = UserAgentService.device(navigator.userAgent);
    let dev = pageProps.device ? pageProps.device : clientDevice;

    // store ref-code
    if (router.query?.r) RewardsService.refCode = router.query?.r;

    TrackingService.setTrackingData('device', dev);
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router]);

  return (
    <>
      <Script strategy="beforeInteractive" src="/bktscripts/jquery.js" />
      <Script strategy="beforeInteractive" src="/bktscripts/owlcarousel.js" />
      <Script
        strategy="beforeInteractive"
        src={`https://www.google.com/recaptcha/enterprise.js?render=${config.recaptcha_key}`}
      />
      <Script
        onLoad={setLoaded}
        src={`https://maps.googleapis.com/maps/api/js?key=${config.maps_api_key}&libraries=places`}
      />
      <Script
        strategy="afterInteractive"
        id="clevertap-script"
        onLoad={setLoadedCleverTap}
        src="https://d2r1yp2w7bby2u.cloudfront.net/js/clevertap.min.js"
      />
      <Script
        id="clevertap-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            var clevertap = {event:[], profile:[], account:[], onUserLogin:[], notifications:[], privacy:[]};
            clevertap.account.push({"id": "${config.clevertap_account_id}"});
            clevertap.spa = true;
            clevertap.privacy.push({optOut: false}); 
            clevertap.privacy.push({useIP: true});`,
        }}
      />
      <Script
        onLoad={setLoadedGtm}
        src={`https://www.googletagmanager.com/gtm.js?id=${config.gta_tracking_id}`}
      />
      <Script
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];`,
        }}
      />

      <ProvideMediaMatchers>
        <Component {...pageProps} />
      </ProvideMediaMatchers>
      <SessionTimeout />
      <LocationPicker />
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeButton={false}
        autoClose={5000}
        theme="colored"
        transition={Slide}
      />
    </>
  );
}

export default appWithTranslation(Barakat, nextI18nConfig);
