import clsx from 'clsx';
import React, {useState, forwardRef, useImperativeHandle} from 'react';

import styles from './styles.module.scss';

function PopModal(props, ref) {
  const [showPop, setShowPop] = useState(false);

  useImperativeHandle(ref, () => ({
    show() {
      showModal();
    },
    hide() {
      hideModal();
    },
  }));

  const showModal = () => {
    setShowPop(true);
  };

  const hideModal = () => {
    setShowPop(false);
  };

  const onClose = () => {
    props.bgaction && setShowPop(false);
  };

  return (
    <div
      className={clsx(
        styles.popmodal,
        props.expanded && styles.expanded,
        showPop && styles.active,
      )}>
      <div onClick={onClose} className={styles.popmodal_overlay}></div>
      <div className={styles.popmodal_content}>{showPop && props.children}</div>
    </div>
  );
}

export default forwardRef(PopModal);
