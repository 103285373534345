import {isUndefined, isNull, keys, isString, isArray} from 'lodash';
import moment from 'moment';

export class AppError extends Error {
  constructor({message, statusCode, data = {}}) {
    super(message);
    this.data = data;
    this.isOperational = true;
    this.statusCode = statusCode;
    this.status = `${statusCode}`.startsWith('4') ? 'fail' : 'error';
    Error.captureStackTrace(this, this.constructor);
  }
}

const formatTime = dateStr => {
  const time = moment.utc(dateStr);
  const now = moment.utc();

  const duration = moment.duration(now.diff(time));
  const minutes = Math.abs(Math.floor(duration.asMinutes()));
  const hours = Math.abs(Math.floor(duration.asHours()));
  const days = Math.abs(Math.floor(duration.asDays()));

  if (minutes < 60) {
    return `${minutes} minutes`;
  } else if (hours < 24) {
    return `${hours} hours`;
  } else if (days < 10) {
    return `${days} days`;
  } else {
    return time.format('YYYY-MMM-DD hh:mm A');
  }
};

const isValidObj = obj => {
  if (isUndefined(obj)) return false;
  else if (isNull(obj)) return false;
  else if (typeof obj === 'object' && keys(obj).length === 0) return false;
  else return true;
};

const isValidArray = array => {
  return (
    !isUndefined(array) &&
    !isNull(array) &&
    isArray(array) &&
    array.length !== 0
  );
};

const isValidString = string => {
  return (
    !isUndefined(string) &&
    !isNull(string) &&
    isString(string) &&
    string !== '' &&
    string !== 'null'
  );
};

const isValidCoordinate = coordinate => {
  return (
    !isUndefined(coordinate) &&
    !isNull(coordinate) &&
    coordinate !== '' &&
    coordinate !== 'null'
  );
};

export {isValidObj, formatTime, isValidArray, isValidString, isValidCoordinate};
